import React from "react"

import Layout from "../../components/layout/layout-stp"
import Seo from "../../components/seo"

import * as style from '../../components/content/content.module.scss'

import { graphql } from 'gatsby'

import Affiliate from "../../components/affiliates/affiliates"



const Contact = ({ data }) => (
  <Layout>
    <Seo title="Services" bodyClass="stp"/>
    
  <div className="column column--sidebar alignright">
  <div className="sticky">
    <span className={style.titleLowercase}>Contact</span>

    <p><strong>Specyal T</strong><br/>
    <small>Founder / Owner / Lead Recording Artist </small></p>

<p><strong>email:</strong><br/>
<a href="mailto:specyalt@specyaltproductions.com">specyalt@specyaltproductions.com</a></p>

<p><strong>phone:</strong><br/>647.273.2327</p>

<p><strong>Instagram:</strong><br/>
<a href="https://www.instagram.com/iamspecyal/">@iamspecyal</a></p>

<hr/>

<p><strong>Mr Specyal</strong><br/>
<small>Specyal Deliveries</small></p>

<p><strong>email:</strong><br/>
<a href="mailto:mrspecyal@specyaltproductions.com">mrspecyal@specyaltproductions.com</a></p>


<hr/>

<p><strong>For all service inquiries</strong><br/>
<a href="mailto:hello@specyaltproductions.com">hello@specyaltproductions.com</a></p>

<hr/>

<p><strong>To book a live performance or an audition</strong><br/>
<a href="mailto:bookings@specyaltproductions.com">bookings@specyaltproductions.com </a></p>
      </div>

    </div>
  <div className="column column--content">



  <span className={style.titleLowercase}>Submit Your Demo</span>
<p>Email: <a href="mailto:bookings@specyaltproductions.com">bookings@specyaltproductions.com</a></p>
<p>Please only send links to your Facebook Page, YouTube, Soundcloud or Website</p>

<hr/>

  <span className={style.subtitleLowercase}>Affiliates</span>

  <div className="grid grid--third">
  <Affiliate
  url="specyaltproductions.com/st"
  name="Specyal T"
  logo={data.logo_st.publicURL}
  />

<Affiliate
  url="tma149.ca/frm_display/tma149-members/entry/20146/"
  name="TMA"
  logo={data.logo_tma.publicURL}
  />
<Affiliate
  url="socan.ca"
  name="A Member Of Socan"
  logo={data.logo_socan.publicURL}
  />
<Affiliate
  url="soundcloud.com/massivesoundcanada"
  name="Massive Sound Studios"
  logo={data.logo_massive.publicURL}
  />
<Affiliate
  url="soundbetter.com/profiles/183812-eamonn-foley"
  name="Foley Productions"
  logo={data.logo_foley.publicURL}
  />
<Affiliate
  url="hyperemesis.org"
  name="HER Foundation"
  logo={data.logo_her.publicURL}
  />

</div>

<hr/>

<span className={style.subtitleLowercase}>Visit these other SPECYAL T PRODUCTIONS sites</span>

<div className="grid grid--third">

  <Affiliate
    url="soundclick.com/specyaltproductions"
    name="Soundclick"
    logo={data.logo_soundclick.publicURL}
    />
    <Affiliate
      url="facebook.com/SPECYALTPRODUCTIONS"
      name="Facebook"
      logo={data.logo_facebook.publicURL}
      />
    <Affiliate
      url="youtube.com/specyalt"
      name="YouTube"
      logo={data.logo_youtube.publicURL}
      />
  <Affiliate
    url="soundcloud.com/specyal-t-productions"
    name="Soundcloud"
    logo={data.logo_soundcloud.publicURL}
    />
  <Affiliate
    url="twitter.com/STPMusicVibes"
    name="Twitter"
    logo={data.logo_twitter.publicURL}
    />


</div>



  </div>
  </Layout>
)

export default Contact



export const query = graphql`
  query {
    logo_her: file(relativePath: {eq: "st-affiliates/affiliate-her_foundation.jpg"}) {
      publicURL
    }
    logo_tma: file(relativePath: { eq: "st-affiliates/affiliate-tma.jpg" }) {
      publicURL
    }
    logo_socan: file(relativePath: {eq: "st-affiliates/affiliate-socan.jpg"}) {
      publicURL
    }
    logo_massive: file(relativePath: { eq: "st-affiliates/affiliate-massive.jpg" }) {
      publicURL
    }
    logo_foley: file(relativePath: {eq: "st-affiliates/affiliate-foley.jpg"}) {
      publicURL
    }
    logo_st: file(relativePath: { eq: "st-affiliates/affiliate-st.jpg" }) {
      publicURL
    }
    logo_soundcloud: file(relativePath: {eq: "links/soundcloud.svg"}) {
      publicURL
    }
    logo_facebook: file(relativePath: { eq: "links/facebook.svg" }) {
      publicURL
    }
    logo_youtube: file(relativePath: {eq: "links/youtube.svg"}) {
      publicURL
    }
    logo_soundclick: file(relativePath: { eq: "links/soundclick.svg" }) {
      publicURL
    }
    logo_twitter: file(relativePath: {eq: "links/twitter.svg"}) {
      publicURL
    }
    logo_itunes: file(relativePath: { eq: "links/itunes.svg" }) {
      publicURL
    }
  }
`